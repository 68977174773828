import * as React from "react"

import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

import defaults from "../../../components/dafaults"

// components
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Search from "../../../components/Search/index"

// styles
import "../../../styles/css/styles.css"

const Veiculo = ({ location }) => {

    const [ vehicle, setVehicle ] = React.useState(null)
    
    const [ size, setSize ] = React.useState(null)
    const [nav1, setNav1] = React.useState(null);
    const [nav2, setNav2] = React.useState(null);
    const [slider1, setSlider1] = React.useState(null);
    const [slider2, setSlider2] = React.useState(null);

    React.useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });

    React.useEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
    })

    const sliderRef1 = React.useRef(slider => (setSlider2(slider)))
    const sliderRef2 = React.useRef(slider => (setSlider1(slider)))

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    const settingsThumbs = {
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        swipeToSlide: true,
        focusOnSelect: true,
        vertical: true,
        infinite: false
    };

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      console.log("🚀 ~ file: index.jsx ~ line 64 ~ Veiculo ~", location.search, `${defaults.api.base}veiculo${decodeURI(location.search)}`)

        fetch(`${defaults.api.base}veiculo${location.search}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("🚀 ~ file: index.jsx ~ line 59 ~ Veiculo ~ result", result.Obj.DetailApl)
                if(vehicle == null)
                    setVehicle(result.Obj)
            })
            .catch(error => console.log('error', error));

        console.log("🚀 ~ file: index.jsx ~ line 15 ~ Produto ~ vehicle", vehicle)

    
    if(vehicle == null) return null
    
    return(
        <>
            
            <Header />
            {
                vehicle.DetailApl ?
                <div className="searchList">
                <div className="container">
                                <h2>Dados Técnicos</h2>
                                <div className="list">
                                    {
                                        size > 991 &&
                                        <div className="thead">
                                            <div>Montadora</div>
                                            <div>Linha</div>
                                            <div>Modelo</div>
                                            <div>Descrição do Modelo</div>
                                            <div>Motor</div>
                                            <div>Ano</div>
                                        </div>
                                    }
                                    <div className="tbody">
                                        {
                                            size < 991 &&
                                            <ul className="thead">
                                                <div>Montadora</div>
                                                <div>Linha</div>
                                                <div>Modelo</div>
                                                <div>Descrição do Modelo</div>
                                                <div>Motor</div>
                                                <div>Ano</div>
                                            </ul>
                                        }
                                        <ul className="content">
                                            <div>{ vehicle.DetailApl[0].Montadora }</div>
                                            <div>{ vehicle.DetailApl[0].Linha }</div>
                                            <div>{ vehicle.DetailApl[0].Modelo }</div>
                                            <div>{ vehicle.DetailApl[0].DescModelo }</div>
                                            <div>{ vehicle.DetailApl[0].Motor }</div>
                                            <div>{ vehicle.DetailApl[0].Ano }</div>
                                        </ul>
                                    </div>
                                </div>
                                <h2>Produtos</h2>
                                <div className="list">
                                    {
                                        size > 991 &&
                                        <div className="thead">
                                            <div>Categoria</div>
                                            <div>Código</div>
                                            <div>Ano</div>
                                        </div>
                                    }
                                    {
                                        vehicle.DetailApl[0].Produtos.map(product => (
                                            <div className="tbody">
                                                {
                                                    size < 991 &&
                                                    <ul className="thead">
                                                        <div>Categoria</div>
                                                        <div>Código</div>
                                                        <div>Ano</div>
                                                    </ul>
                                                }
                                                <ul className="content">
                                                    <div>{ product.Key }</div>
                                                    <div><a href={`/busca/produto?cod=${product.Value}`}>{ product.Value }</a></div>
                                                    <div>{ vehicle.DetailApl[0].Ano }</div>
                                                </ul>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                :
                    <div className="notFound">Nenhum resultado encontrado</div> 
            }
            <Search internal={true} />
            <Footer />

        </>

    )
}

export default Veiculo